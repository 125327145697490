<template>
  <RouterLink v-if="!asButton" v-bind="$attrs" v-on="$listeners">
    <slot />
  </RouterLink>
  <MButton
    v-else
    v-bind="$attrs"
    v-on="$listeners"
    @click="$router.push($attrs.to)"
  >
    <slot />
  </MButton>
</template>

<script>
export default {
  name: 'FlotoLink',
  inheritAttrs: false,
  props: {
    asButton: { type: Boolean, default: false },
  },
  methods: {
    go() {
      this.$router.push(this.$attrs.to)
    },
  },
}
</script>
