<template>
  <MValidationProvider
    ref="provider"
    slim
    :rules="rules"
    :name="validationLabel || label"
    :debounce="200"
    :mode="mode"
    :immediate="immediate"
    :vid="vid"
  >
    <MFormItem
      slot-scope="{
        errors,
        invalid,
        valid,
        validated,
        pending,
        required,
        ...slotData
      }"
      :error="invalid && hideMessage === false ? errors[0] : ''"
      :required="required"
      :validate-status="
        validated && !pending ? (valid ? 'success' : 'error') : ''
      "
      :label="label"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <slot name="label" />
      <slot v-bind="slotData">
        <slot name="before-input" />
        <MInput
          ref="input"
          v-model="inputValue"
          v-bind="$attrs"
          :type="inputType || type"
          @update="$emit('update', $event)"
          @blur="$emit('blur', $event)"
          v-on="listeners"
        >
          <template
            v-for="(_, name) in $scopedSlots"
            v-slot:[name]="nestedSlot"
          >
            <slot :name="name" v-bind="nestedSlot" />
          </template>
          <slot name="input-children" />
        </MInput>
        <slot name="after-input" />
      </slot>
    </MFormItem>
  </MValidationProvider>
</template>

<script>
export default {
  name: 'FlotoFormItem',
  model: {
    event: 'update',
  },
  props: {
    value: { type: [Array, Object, String, Number], default: undefined },
    rules: { type: [Object, String], default: '' },
    label: { type: String, required: false, default: '' },
    validationLabel: { type: String, default: undefined },
    vid: { type: String, default: undefined },
    mode: { type: String, default: 'flotoValidationMode' },
    immediate: { type: Boolean, default: false },
    inputType: { type: String, default: undefined },
    type: { type: String, default: undefined },
    hideMessage: { type: Boolean, default: false },
    wrapperCol: {
      type: Object,
      default() {
        return { xxl: 10, xl: 9, lg: 8 }
      },
    },
    labelCol: {
      type: Object,
      default() {
        return { xxl: 2, xl: 3, lg: 4 }
      },
    },
  },
  computed: {
    listeners() {
      const { update, input, change, blur, ...listeners } = this.$listeners
      return listeners
    },
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update', value)
      },
    },
  },
  methods: {
    setValue(value) {
      this.inputValue = value
    },
    addError(error) {
      this.$refs.provider.setErrors(Array.isArray(error) ? error : [error])
    },
    focus() {
      if (this.$refs.input) {
        this.$refs.input.focus()
      }
    },
    validate() {
      return this.$refs.provider.validate()
    },
  },
}
</script>
