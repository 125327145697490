<script>
import Merge from 'lodash/merge'
import axios from 'axios'
import { getLocaleDataApi } from '@api/locale-api'
import Bus from '@utils/emitter'
export default {
  name: 'FlotoModule',
  props: {
    layout: { default: 'layout', type: [String, Object] },
  },
  data() {
    return { localeLoaded: false, customLocale: null }
  },
  created() {
    this.getCustomLocale()
  },
  methods: {
    // fetch custom label from specific file
    getCustomLocale() {
      axios(`api/public/override/lang/data/${this.$i18n.locale}`)
        .then((data) => {
          if (Object.keys(data.data || {}).length) {
            this.customLocale = data.data
          }
        })
        .catch(() => {
          this.customLocale = null
        })
        .finally(() => {
          this.loadLocale()
        })
    },
    loadLocale() {
      const currentModule = this.$modules.getCurrentModule()
      const currentMessages = this.$i18n.getLocaleMessage(this.$i18n.locale)
      // get module key from module
      const moduleI18nKey = currentModule.getTranslationKey()
      // get dependent translation keys for current module
      const dependentModuleI18nKey =
        currentModule.getDependentTranslationKeys() || []
      // skip merging messages if key is already there
      if (!(moduleI18nKey in currentMessages) || this.customLocale) {
        getLocaleDataApi(this.$i18n.locale, [
          moduleI18nKey,
          ...dependentModuleI18nKey,
        ]).then((messages) => {
          const mergedMessages = Merge(
            {
              ...messages,
            },
            // add client specific custom label from specific file
            { ...(this.customLocale ? this.customLocale : {}) }
          )
          this.$i18n.mergeLocaleMessage(this.$i18n.locale, mergedMessages)
          this.localeLoaded = true
          window.globallyLocaleLoaded = true
          Bus.$emit('app:globallyLocaleLoaded')
        })
      } else {
        this.localeLoaded = true
      }
    },
    // loadLocale() {
    //   const currentModule = this.$modules.getCurrentModule()
    //   if (currentModule.getLocaleMessages) {
    //     const currentMessages = this.$i18n.getLocaleMessage(this.$i18n.locale)
    //     // get module key from module
    //     const moduleI18nKey = currentModule.getTranslationKey()
    //     // skip merging messages if key is already there
    //     if (!(moduleI18nKey in currentMessages) || this.customLocale) {
    //       currentModule
    //         .getLocaleMessages(this.$i18n.locale)
    //         .then((messages) => {
    //           const mergedMessages = Merge(
    //             {
    //               [moduleI18nKey]: messages,
    //             },
    //             // add client specific custom label from specific file
    //             { ...(this.customLocale ? this.customLocale : {}) }
    //           )
    //           this.$i18n.mergeLocaleMessage(this.$i18n.locale, mergedMessages)
    //           this.localeLoaded = true
    //         })
    //     } else {
    //       this.localeLoaded = true
    //     }
    //   } else {
    //     this.localeLoaded = true
    //   }
    // },
  },
  render() {
    if (this.$scopedSlots.default && this.localeLoaded) {
      return this.$scopedSlots.default()
    }
    return null
  },
}
</script>
