<template>
  <MValidationObserver ref="observer" slim>
    <!-- <template v-slot="scopeData"> -->
    <MForm
      autocomplete="off"
      v-bind="$attrs"
      :submit-text="$tc('submit')"
      @submit="submit"
      @reset="reset"
    >
      <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
        <slot
          :name="name"
          v-bind="slotData"
          :validate="validate"
          :submit="submit"
          :reset="reset"
        />
      </template>
    </MForm>
    <!-- </template> -->
  </MValidationObserver>
</template>

<script>
export default {
  name: 'FlotoForm',
  provide() {
    const flotoFormContext = {}
    Object.defineProperty(flotoFormContext, 'form', {
      enumerable: true,
      get: () => {
        return this
      },
    })
    return { flotoFormContext }
  },
  props: {
    // eslint-disable-next-line
    showNotification: { type: Boolean, default: true },
  },
  methods: {
    validate() {
      return this.$refs.observer.validate().then((response) => {
        const e = new Error('invalid')
        e._handled = true
        return response ? Promise.resolve(true) : Promise.reject(e)
      })
    },
    submit(...args) {
      this.$refs.observer.validate().then((response) => {
        if (response) {
          this.$emit('submit', ...args)
        } else {
          if (this.showNotification) {
            this.$errorNotification({
              message: this.$t('error'),
              description: this.$t('validation_failed_message'),
              key: 'formError',
            })
          }
        }
      })
    },
    resetValidation() {
      this.$refs.observer.reset()
    },
    reset() {
      requestAnimationFrame(() => {
        this.$refs.observer.reset()
        this.$root.$emit('reset')
        this.$emit('reset')
      })
    },
  },
}
</script>
