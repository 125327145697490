<template>
  <MDrawer
    ref="drawer"
    v-bind="$attrs"
    v-on="listeners"
    @hide="handlevisibleChange(false)"
    @show="handlevisibleChange(true)"
  >
    <template v-slot:trigger="slotData">
      <slot name="trigger" v-bind="slotData"><span /></slot>
    </template>
    <template v-slot:title>
      <h5 class="mb-0 text-ellipsis w-11/12">
        <slot name="title" />
      </h5>
    </template>
    <Transition mode="out-in" appear name="placeholder">
      <div v-if="drawerLoaded" class="flex h-100 flex-col">
        <div
          class="h-full"
          style="padding: 24px"
          :style="{ paddingBottom: $scopedSlots.actions ? '65px' : '0' }"
        >
          <FlotoScrollView v-if="scrolledContent">
            <div
              class="w-full overflow-hidden flex-1 flex flex-col scrolled-drawer-content"
            >
              <slot />
            </div>
          </FlotoScrollView>
          <slot v-else />
        </div>
      </div>
    </Transition>
    <div v-if="$scopedSlots.actions" class="actions">
      <slot name="actions" :hide="hide" />
    </div>
  </MDrawer>
</template>

<script>
export default {
  name: 'FlotoDrawer',
  props: {
    open: { type: Boolean, default: false },
    // eslint-disable-next-line
    scrolledContent: { type: Boolean, default: true },
  },
  data() {
    return {
      drawerLoaded: false,
    }
  },
  computed: {
    listeners() {
      const { hide, show, ...listeners } = this.$listeners
      return listeners
    },
  },
  watch: {
    open: {
      immediate: true,
      handler(newValue) {
        if (this.$refs.drawer) {
          if (newValue) {
            this.show()
          } else {
            this.hide()
          }
        }
      },
    },
  },
  mounted() {
    if (this.open) {
      this.show()
    }
  },
  methods: {
    hide() {
      this.$refs.drawer.hide()
    },
    show() {
      this.$refs.drawer.show()
      setTimeout(() => {
        this.drawerLoaded = true
      })
    },
    handlevisibleChange(isVisible) {
      if (!isVisible) {
        setTimeout(() => this.$emit('hide'), 500)
      } else {
        this.$emit('show')
        setTimeout(() => {
          this.drawerLoaded = true
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.actions {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  background-color: transparent;
  border-bottom-left-radius: 15px;

  @apply border-solid border-t border-neutral-lighter border-l-0 border-r-0 border-b-0 mx-6;
}
</style>
