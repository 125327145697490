<script>
import Mousetrap from 'mousetrap'
import Bus from '@utils/emitter'
import { SHORTCUT_MAP } from '@utils/shortcuts-map'
import { LicenseComputed } from '@state/modules/license'
import { PreferenceComputed } from '@state/modules/preference'
import { authComputed } from '@state/modules/auth'
import { SupportPortalConfigComputed } from '@state/modules/support-portal-config'

Mousetrap.prototype.stopCallback = function (e, element, combo) {
  // if popover is open and has container class "ignore-mousetrap" then stop
  // @TODO get dynamic ant prefix
  if (document.querySelector('.ant-popover-content .ignore-mousetrap')) {
    return true
  }
  // if the element has the class "mousetrap" then no need to stop
  if (
    (' ' + element.className + ' ').indexOf(' mousetrap ') > -1 ||
    element.closest('.mousetrap')
  ) {
    return false
  }

  // stop for input, select, and textarea
  return (
    element.tagName === 'INPUT' ||
    element.tagName === 'SELECT' ||
    element.tagName === 'TEXTAREA' ||
    (element.contentEditable && element.contentEditable === 'true')
  )
}

export default {
  name: 'FlotoShortcutHandler',
  props: { isPortal: { type: Boolean, default: false } },
  data() {
    this.boundShortcuts = []
    return {}
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
    ...authComputed,
    ...SupportPortalConfigComputed,
  },
  watch: {
    availableModulesInLicense: {
      handler: 'bindAvailableShortcuts',
      immediate: true,
    },
    myAllowedModules: {
      handler: 'bindAvailableShortcuts',
      immediate: true,
    },
    shortcutEnabled(newValue) {
      if (newValue === false) {
        this.unbindAllShortcuts()
      } else {
        this.bindAppLevelShortcuts()
      }
    },
    loggedIn(newValue) {
      if (newValue === false) {
        this.unbindAllShortcuts()
      }
    },
  },
  beforeDestroy() {
    this.unbindAllShortcuts()
  },
  methods: {
    bindAvailableShortcuts() {
      this.unbindAllShortcuts()
      if (!this.shortcutEnabled) {
        return
      }
      if (!this.loggedIn) {
        return
      }
      const bindableShortcuts = SHORTCUT_MAP().filter((x) => {
        if (!x.supportedModules) {
          return true
        }
        return x.supportedModules(
          this.availableModulesInLicense,
          this.myAllowedModules,
          this.isPortal,
          {
            allowRequesterToAccessKB: this.allowRequesterToAccessKB,
            allowRequesterToAccessServiceRequest:
              this.allowRequesterToAccessServiceRequest,
            allowRequesterToAccessMyApprovals:
              this.allowRequesterToAccessMyApprovals,
          }
        )
      })
      this.boundShortcuts = bindableShortcuts
      this.boundShortcuts.map((s) => {
        Mousetrap.bind(s.key, (...args) => {
          // const e = args[0]
          // if (e.preventDefault) {
          //   e.preventDefault()
          // } else {
          //   // internet explorer
          //   e.returnValue = false
          // }
          if (s.preCommand) {
            Bus.$emit(s.preCommand, ...[...args, s.preCommand, s.command])
          }
          setTimeout(() => {
            Bus.$emit(s.command, ...[...args, s.command])
          }, s.preCommandGap || 0)
        })
      })
      this.bindAppLevelShortcuts()
    },
    bindAppLevelShortcuts() {
      // bind shortcuts which are for route navigations
      this.boundShortcuts
        .filter((s) => s.routeArgs)
        .forEach((shortcut) => {
          Bus.$on(shortcut.command, (e) => {
            e.preventDefault()
            e.stopPropagation()
            const route =
              typeof shortcut.routeArgs === 'function'
                ? shortcut.routeArgs(this.isPortal)
                : shortcut.routeArgs
            this.$router.push(this.$modules.getModuleRoute(...route))
          })
        })
    },
    unbindAllShortcuts() {
      this.boundShortcuts
        .filter((s) => s.routeArgs)
        .forEach((shortcut) => {
          Bus.$off(shortcut.command)
        })
      Mousetrap.reset()
    },
  },
  render() {
    return this.$scopedSlots.default()
  },
}
</script>
