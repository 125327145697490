<template>
  <VWait
    :for="uniqueName"
    class="w-full flex-1 min-h-full flex flex-col"
    :class="classes"
    :mode="mode"
    :transition="transition"
    v-bind="$attrs"
  >
    <template v-slot:waiting>
      <slot name="waiting">
        <MRow class="w-full min-h-full">
          <MCol :xxl="12" class="flex-grow">
            <slot name="placeholder">
              <MPlaceholder
                :width="width"
                :height="height"
                v-bind="$attrs"
                primary-color="var(--loader-primary-color)"
                secondary-color="var(--loader-secondary-color)"
              >
              </MPlaceholder>
            </slot>
          </MCol>
        </MRow>
      </slot>
    </template>
    <slot v-if="!loading" />
  </VWait>
</template>

<script>
import { generateId } from '@utils/id'

export default {
  name: 'FlotoContentLoader',
  props: {
    loading: { type: Boolean, required: true },
    height: { type: Number, default: 300 },
    width: {
      type: [Number, String],
      default() {
        return '100%'
      },
    },
    classes: { type: String, default: '' },
    mode: { type: String, default: 'out-in' },
    transition: { type: String, default: 'placeholder' },
  },
  data() {
    return { uniqueName: '' }
  },
  watch: {
    loading: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.$loader.start(this.uniqueName)
        } else {
          this.$loader.end(this.uniqueName)
        }
      },
    },
  },
  mounted() {
    if (this.loading) {
      this.$loader.start(this.uniqueName)
    }
  },
  beforeMount() {
    this.uniqueName = generateId()
  },
}
</script>
