<template>
  <div class="flex flex-1 min-w-0">
    <div class="flex flex-col min-w-0 flex-grow">
      <div
        class="min-h-full flex-no-wrap overflow-x-hidden min-h-0 flex"
        :class="{ 'h-0': noHeight }"
      >
        <div
          class="flex flex-col overflow-x-hidden flex-grow"
          :class="{ 'overflow-y-hidden': hideVerticalOverflow }"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FlotoFixedView',
  props: {
    gutter: { type: Number, default: 46 },
    // eslint-disable-next-line
    noHeight: { type: Boolean, default: true },
    // eslint-disable-next-line
    hideVerticalOverflow: { type: Boolean, default: true },
  },
}
</script>
