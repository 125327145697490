<template>
  <FlotoLink v-bind="$attrs">
    <MIcon :name="`long-arrow-${isRtl ? 'right' : 'left'}`" />
  </FlotoLink>
</template>

<script>
import { authComputed } from '@state/modules/auth'

export default {
  name: 'FlotoBackButton',
  computed: {
    ...authComputed,
  },
}
</script>
