<template>
  <div>
    <slot
      name="trigger"
      :expand="expand"
      :collapse="collapse"
      :toggle="toggle"
      :expanded="expanded"
    >
    </slot>
    <FlotoExpand>
      <div v-if="expanded">
        <slot
          :toggle="toggle"
          :expand="expand"
          :collapse="collapse"
          :expanded="expanded"
        />
      </div>
    </FlotoExpand>
  </div>
</template>

<script>
export default {
  name: 'FlotoExpandableView',
  props: { defaultExpanded: { type: Boolean, default: false } },
  data() {
    return {
      expanded: this.defaultExpanded,
    }
  },
  methods: {
    expand() {
      this.expanded = true
    },
    collapse() {
      this.expanded = false
    },
    toggle() {
      this.expanded = !this.expanded
    },
  },
}
</script>
