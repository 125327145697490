<template>
  <div ref="container" :style="containerStyle">
    <div ref="content" :style="contentStyle">
      <slot />
    </div>
    <a v-if="shouldShowLink" @click="toggle">
      {{ $t('read') }} {{ $t(expanded ? 'less' : 'more') }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'FlotoReadMore',
  props: {
    height: { type: Number, default: 8 * 20 },
  },
  data() {
    this.lastContent = null
    return {
      expanded: false,
      calculatingHeight: true,
      contentHeight: undefined,
    }
  },
  computed: {
    shouldShowLink() {
      if (this.contentHeight > this.height) {
        return true
      }
      return false
    },
    containerStyle() {
      if (this.calculatingHeight) {
        return { position: 'absolute', visibility: 'hidden' }
      }
      return {}
    },
    contentStyle() {
      const style = {
        overflow: 'hidden',
        transition: 'max-height 0.2s ease-in',
      }
      if (this.calculatingHeight) {
        return style
      }
      if (this.expanded) {
        if (this.contentHeight) {
          style.maxHeight = `${this.contentHeight}px`
        }
      } else {
        style.maxHeight = `${this.height}px`
      }
      return style
    },
  },
  watch: {
    // contentHeight() {
    //   if (this.contentHeight <= this.height) {
    //     this.expanded = true
    //   }
    // },
  },
  mounted() {
    setTimeout(() => {
      this.calculateContentHeight()
    }, 450)
  },
  updated() {
    if (this.lastContent !== this.$refs.content.innerHTML) {
      setTimeout(() => {
        this.calculateContentHeight()
      }, 450)
    }
  },
  methods: {
    calculateContentHeight() {
      this.calculatingHeight = true
      let currentExpandStatus = this.expanded
      this.expanded = true
      this.contentHeight = undefined
      this.lastContent = this.$refs.content.innerHTML
      this.$nextTick(() => {
        this.contentHeight = this.$refs.container.offsetHeight + 20
        this.expanded = currentExpandStatus
        this.calculatingHeight = false
      })
    },
    toggle() {
      this.expanded = !this.expanded
    },
  },
}
</script>

<style lang="less">
[data-readmore] {
  transition: box-shadow 0.2s linear;
}
</style>
