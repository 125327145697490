<template>
  <div
    :class="shape"
    :style="{
      backgroundColor: bg,
      height: `${size}px`,
      width: `${size}px`,
      minWidth: `${size}px`,
    }"
    @click="$emit('click')"
  ></div>
</template>

<script>
export default {
  name: 'FlotoDot',
  props: {
    bg: { type: String, default: '#cecece' },
    size: { type: Number, default: 12 },
    shape: { type: String, default: 'dot' },
  },
}
</script>

<style lang="less" scoped>
.dot {
  @apply rounded-full inline-block;
}
.square {
  @apply rounded inline-block;
}
</style>
