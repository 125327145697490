<template>
  <MConfirmBtn
    :placement="placement"
    :ok-text="okText"
    :cancel-text="cancelText"
    :overlay-style="{ zIndex: 9999, ...(maxWidth ? { maxWidth } : {}) }"
    v-bind="$attrs"
    @hide="$emit('cancel')"
    @cancel="$emit('cancel')"
    @confirm="$emit('confirm')"
    v-on="listeners"
  >
    <template v-slot:title>
      {{ message }}
    </template>
    <slot name="trigger">
      <a
        href="javascript:;"
        class="text-secondary-red delete-button"
        @click.stop="$emit('trigger')"
        v-on="listeners"
      >
        <slot>
          <MTooltip v-if="showTooltip">
            <template v-slot:trigger>
              <MIcon name="trash-alt" />
            </template>
            {{ $t('delete') }}
          </MTooltip>
          <MIcon v-else name="trash-alt" />
        </slot>
      </a>
    </slot>
  </MConfirmBtn>
</template>

<script>
export default {
  name: 'FlotoDeleteBtn',
  props: {
    message: { type: String, required: true },
    okText: {
      type: String,
      default() {
        return this.$tc('yes')
      },
    },
    cancelText: {
      type: String,
      default() {
        return this.$tc('cancel')
      },
    },
    maxWidth: { type: String, default: undefined },
    placement: { type: String, required: false, default: 'bottomRight' },
    // eslint-disable-next-line
    showTooltip: { type: Boolean, default: true },
  },
  computed: {
    listeners() {
      const { confirm, cancel, ...listeners } = this.$listeners
      return listeners
    },
  },
}
</script>
