<template>
  <FlotoFixedView :gutter="0">
    <div class="flex flex-col h-100 overflow-x-hidden">
      <AdminHeader :breadcrumb="breadcrumb" :hide-breadcrumb="hideBreadcrumb">
        <slot name="back-button">
          <FlotoBackButton
            v-if="backLink"
            :to="backLink"
            class="text-neutral-light mr-2"
          />
        </slot>
        {{ pageTitle }}
        <template v-if="$scopedSlots.breadcrumb" v-slot:breadcrumb>
          <slot name="breadcrumb" />
        </template>
      </AdminHeader>
      <slot name="header-content" />
      <MDivider v-if="showDivider" class="mt-0" />
      <div v-if="removeScroll" class="flex flex-1 flex-col min-h-0">
        <slot />
      </div>
      <FlotoScrollView v-else>
        <MRow :gutter="0" class="overflow-hidden flex-1">
          <MCol class="min-w-0 flex flex-1">
            <div class="w-full flex min-w-0">
              <slot />
            </div>
          </MCol>
        </MRow>
      </FlotoScrollView>
    </div>
  </FlotoFixedView>
</template>

<script>
import AdminHeader from './admin-header'

export default {
  name: 'AdminPage',
  components: { AdminHeader },
  props: {
    backLink: { type: [String, Object], default: undefined },
    hideBreadcrumb: { type: Boolean, default: false },
    // eslint-disable-next-line
    showDivider: { type: Boolean, default: true },
    removeScroll: { type: Boolean, default: false },
  },
  computed: {
    breadcrumb() {
      const breadcrumbs = this.$route.matched
        .filter((r) => r.meta.breadcrumbKey || r.meta.titleFn)
        .map((r) => {
          let breadcrumbKey = r.meta.breadcrumbKey
          if (typeof breadcrumbKey === 'function') {
            breadcrumbKey = breadcrumbKey(
              this.$route,
              (key) => this.$tc(key, 1),
              (key) => this.$tc(key, 2)
            )
            if (!breadcrumbKey) {
              return undefined
            }
          }
          if (breadcrumbKey === 'currentModule') {
            breadcrumbKey =
              this.$route.params.moduleName || this.$constants.REQUEST
          }
          let title = this.$tc(breadcrumbKey, 2)
          if (r.meta.titleFn) {
            title = r.meta.titleFn(
              this.$route,
              (key) => this.$tc(key, 1),
              (key) => this.$tc(key, 2)
            )
          }
          return {
            route: r.path,
            title: title,
            active: r.meta.nonLinked ? true : r.regex.test(this.$route.path),
          }
        })
        .filter(Boolean)
      return breadcrumbs
    },
    pageTitle() {
      const items = this.breadcrumb
      return items[items.length - 1].title
    },
  },
}
</script>
