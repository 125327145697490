<template>
  <MModal
    ref="modal"
    centered
    overlay-class-name="floto-confirm-modal"
    v-bind="$attrs"
    @success="$emit('confirm')"
    @cancel="$emit('cancel')"
    @hide="$emit('hide')"
  >
    <slot>
      <MRow>
        <MCol :size="12" class="flex">
          <MCol
            :size="3"
            class="icon-container"
            :class="{ 'without-shadow': noIconShadow }"
          >
            <slot name="icon"></slot>
          </MCol>
          <MCol :size="9" class="flex items-center">
            <slot name="message"></slot>
          </MCol>
        </MCol>
      </MRow>
    </slot>
    <template v-slot:footer="{ success, cancel }">
      <slot
        name="actions"
        :success="success"
        :cancel="cancel"
        :processing="processing"
      >
        <MButton id="yes-btn" :loading="processing" @click.stop="success">{{
          $t('yes')
        }}</MButton>
        <MButton
          v-if="!processing"
          id="no-btn"
          class="mx-1"
          variant="default"
          @click.stop="cancel"
        >
          {{ $t('no') }}
        </MButton>
      </slot>
    </template>
  </MModal>
</template>

<script>
export default {
  name: 'FlotoConfirmModal',
  props: {
    open: { type: Boolean, default: false },
    noIconShadow: { type: Boolean, default: false },
    processing: { type: Boolean, default: false },
  },
  watch: {
    open: {
      handler: 'toggleModal',
    },
  },
  mounted() {
    this.toggleModal(this.open)
  },
  methods: {
    toggleModal(newValue) {
      if (newValue) {
        this.openModal()
      } else {
        this.closeModal()
      }
    },
    openModal() {
      this.$refs.modal.show()
    },
    closeModal() {
      this.$refs.modal.hide()
    },
  },
}
</script>

<style lang="less">
.floto-confirm-modal {
  .@{ant-prefix}-modal-content {
    max-width: 450px;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    box-shadow: 2px 2px 30px -4px rgba(0, 0, 0, 0.6);
    // box-shadow: 2px 2px 30px -4px rgba(0, 0, 0, 0.6);
    // box-shadow: 2px 2px 30px -4px rgba(0, 0, 0, 0.6);

    &.without-shadow {
      box-shadow: none;
    }
  }
}
</style>
