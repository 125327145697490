<template>
  <MTreeSelect
    class="floto-cascader"
    dropdown-match-select-width
    :class="{ 'w-full': asInput, 'trigger-as-tag': asTag }"
    overlay-class-name="dropdown-with-colors"
    :disabled="disabled"
    :placeholder="placeholder"
    :options="innerOptions"
    :multiple="multiple"
    v-bind="attrs"
    :value="internalValue"
    @change="handleChange"
  />
</template>

<script>
// import { findValuePath, findValueObject } from '@data/recursive'

export default {
  name: 'FlotoTreePicker',
  inheritAttrs: false,
  model: {
    event: 'change',
  },
  props: {
    defaultOpen: { type: Boolean, default: false },
    asTag: { type: Boolean, default: false },
    asInput: { type: Boolean, default: false },
    value: { type: [Number, String, Object, Array], default: undefined },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Select' },
    multiple: { type: Boolean, default: false },
    separator: {
      type: String,
      default() {
        if (this.asInput) {
          return ' | '
        }
        return undefined
      },
    },
  },
  data() {
    return {
      // isActiveHover: false,
      // isOpen: false,
      // currentSelectedItemText: undefined,
      internalValue: undefined,
      // selectedItem: undefined,
    }
  },
  computed: {
    innerOptions() {
      let options = this.$attrs.options || this.options || []
      if (this.$attrs['additional-options']) {
        options = (this.$attrs['additional-options'] || []).concat(options)
      }
      return options
    },
    hasNoneSupport() {
      return (
        (
          this.innerOptions.filter(
            (i) => i.value === 0 || i.id === 0 || i.key === 0
          ) || []
        ).length > 0
      )
    },
    listeners() {
      const { change, ...listeners } = this.$listeners
      return listeners
    },
    attrs() {
      const { options, ...attrs } = this.$attrs
      return attrs
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue || (this.hasNoneSupport && newValue === 0)) {
          if (Array.isArray(newValue)) {
            this.internalValue = newValue.map((v) => v.value || v)
          } else {
            this.internalValue = String(newValue.value || newValue)
          }
          // this.selectedItem = findValueObject(this.innerOptions, newValue)
        } else {
          this.internalValue = undefined
        }
      },
    },
  },
  methods: {
    handleChange(value, label, ...args) {
      this.$emit(
        'selected',
        label.map((l, index) => ({ text: l, key: value[index] }))
      )
      this.$emit('change', value || this.unassignedValue, label, ...args)
      // this.$nextTick(() => {
      // })
    },
  },
}
</script>

<style lang="less" scoped>
.dropdown-with-colors {
  .dropdown-item {
    @apply flex items-center cursor-pointer;
  }
}
</style>
