<template>
  <MModal
    ref="modal"
    centered
    overlay-class-name="floto-confirm-modal"
    v-bind="$attrs"
    @success="$emit('confirm')"
    @hide="$emit('hide')"
  >
    <FlotoForm ref="formRef" :show-notification="false" @submit="handleSubmit">
      <!-- <FlotoFormItem v-model="inputValue" :label="label" rules="required" /> -->
      <PasswordInput
        v-model="inputValue"
        :placeholder="label"
        :label="label"
        rules="required"
      >
        <template v-slot:prefix>
          <MIcon name="unlock-alt" />
        </template>
      </PasswordInput>
      <template v-slot:submit><span /></template>
    </FlotoForm>
    <template v-slot:footer="{ success, cancel }">
      <slot
        name="actions"
        :success="success"
        :cancel="cancel"
        :processing="processing"
      >
        <MButton :loading="processing" @click.stop="handleSuccess(success)">{{
          $t('submit')
        }}</MButton>
        <MButton v-if="!processing" variant="default" @click.stop="cancel">
          {{ $t('cancel') }}
        </MButton>
      </slot>
    </template>
  </MModal>
</template>

<script>
import PasswordInput from '@components/password-input'
export default {
  name: 'FlotoPropmptModal',
  components: { PasswordInput },
  props: {
    open: { type: Boolean, default: false },
    noIconShadow: { type: Boolean, default: false },
    processing: { type: Boolean, default: false },
    label: {
      type: String,
      default() {
        return `${this.$tc('password')}`
      },
    },
  },
  data() {
    return {
      inputValue: '',
    }
  },
  watch: {
    open: {
      handler: 'toggleModal',
    },
  },
  mounted() {
    this.toggleModal(this.open)
  },
  methods: {
    submit() {
      this.$refs.formRef.submit()
    },
    handleSuccess(successFn) {
      this.submit()
    },
    handleSubmit() {
      this.$emit('confirm', this.inputValue)
    },
    toggleModal(newValue) {
      if (newValue) {
        this.openModal()
      } else {
        this.closeModal()
      }
    },
    openModal() {
      this.inputValue = ''
      this.$refs.modal.show()
    },
    closeModal() {
      this.inputValue = ''
      this.$refs.modal.hide()
    },
  },
}
</script>

<style lang="less">
.floto-confirm-modal {
  .@{ant-prefix}-modal-content {
    max-width: 450px;
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    -webkit-box-shadow: 2px 2px 30px -4px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 2px 2px 30px -4px rgba(0, 0, 0, 0.6);
    box-shadow: 2px 2px 30px -4px rgba(0, 0, 0, 0.6);
    &.without-shadow {
      box-shadow: none;
    }
  }
}
</style>
