<template>
  <div class="flex items-center text-secondary-red justify-center">
    <slot>
      <MIcon name="exclamation-triangle" class="mr-2" size="lg" />
      <div class="text-secondary-red">
        No Item Selected! this page needs to be designed
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'FlotoNoItemSelected',
}
</script>
