<template>
  <MRow
    v-if="type === 'flotomate'"
    :gutter="0"
    class="items-center justify-center flex-1"
  >
    <MCol :size="12">
      <!-- <MRow :gutter="0" class="justify-center">
        <MCol :size="colSize">
          <NoData class="w-full" />
        </MCol>
      </MRow> -->
      <MRow :gutter="0">
        <MCol>
          <component
            :is="$attrs.size === 'small' ? 'h5' : 'h2'"
            class="text-center mt-4 font-normal flex justify-center items-center text-neutral-light"
          >
            <MIcon name="info-circle" class="mx-1" />
            <slot name="message">{{ $t('no_data_message') }}</slot>
          </component>
          <slot name="action"></slot>
        </MCol>
      </MRow>
    </MCol>
  </MRow>
  <div v-else class="flex items-center justify-center flex-1">
    <MNoData v-bind="$attrs">
      <slot />
    </MNoData>
  </div>
</template>

<script>
// import NoData from '@assets/images/no-data.svg'

export default {
  name: 'FlotoNoData',
  // components: { NoData },
  props: {
    type: { type: String, default: 'flotomate' },
    // colSize: { type: Number, default: 4 },
  },
}
</script>
