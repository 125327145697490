<script>
export default {
  name: 'FlotoHook',
  created() {
    // As soon as this component is created, it's parent component
    // must have already been created, so we immediately trigger the hook.
    this.$emit('created')

    const triggerMounted = () => this.$emit('mounted')
    this.$parent.$on('hook:mounted', triggerMounted)

    const triggerUpdated = () => this.$emit('updated')
    this.$parent.$on('hook:updated', triggerUpdated)

    this.$once('hook:beforeDestroy', () => {
      this.$parent.$off('hook:mounted', triggerMounted)
      this.$parent.$off('hook:updated', triggerUpdated)
    })
  },
  render() {
    return null
  },
}
</script>
