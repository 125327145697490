<template>
  <MRow :gutter="0">
    <MCol class="flex min-w-0 my-2">
      <MRow :gutter="0" class="w-full flex-no-wrap">
        <MCol auto-size>
          <h3 class="mb-0">
            <slot name="back-button">
              <FlotoBackButton
                v-if="backLink"
                :to="backLink"
                class="text-neutral-light mx-2"
              />
            </slot>
          </h3>
        </MCol>
        <MCol class="min-w-0">
          <div class="flex flex-1 items-center">
            <div class="flex flex-1 flex-col self-start min-w-0">
              <h3
                class="flex items-center mb-0 font-semibold header-section-m text-primary"
              >
                <slot name="before-title"></slot>
                <div class="flex-1 min-w-0 head-title-m">
                  <div class="inline-flex w-full items-center">
                    <div class="text-ellipsis">
                      <MTooltip placement="bottomLeft">
                        <template v-slot:trigger>
                          <span>
                            {{ trimmedTitle }}
                          </span>
                        </template>
                        {{ title }}
                      </MTooltip>
                      <slot name="title" />
                    </div>
                    <slot name="title-append"></slot>
                  </div>
                </div>
              </h3>
              <div>
                <slot />
              </div>
            </div>
            <div class="flex items-center">
              <slot name="after-title"></slot>
            </div>
          </div>
        </MCol>
      </MRow>
    </MCol>
    <slot name="additional-rows" />
    <MCol v-if="useDivider" :size="12">
      <MDivider class="my-2" />
    </MCol>
  </MRow>
</template>

<script>
export default {
  name: 'FlotoPageHeader',
  props: {
    backLink: { type: [String, Object], default: undefined },
    title: { type: String, default: undefined },
    useDivider: { type: Boolean, default: false },
  },
  computed: {
    trimmedTitle() {
      if ((this.title || '').length > 100) {
        return `${this.title.substring(0, 100)}...`
      }
      return this.title
    },
  },
}
</script>
