<script>
import BrandingMixin from '@src/mixins/branding'
import { getColorFadeValue } from '@utils/color'
import { transformColorPaletteForServer } from '@modules/organization/views/theme/utils'
export default {
  name: 'FlotoThemeComponent',
  mixins: [BrandingMixin],
  provide() {
    const themeComponentContext = {
      name: 'themeComponentData',
      updateThemeStyle: this.updateThemeStyle,
    }
    return { themeComponentContext }
  },
  props: {
    portal: {
      type: String,
      required: true,
    },
  },
  watch: {
    'branding.themeColorPalette': {
      immediate: true,
      handler(newValue) {
        let formattedValue
        if (newValue[this.portal]) {
          formattedValue = transformColorPaletteForServer(newValue[this.portal])
        }
        this.updateThemeStyle(formattedValue)
      },
    },
  },
  mounted() {
    this.addStyleTag()
    if (this.$route.query.colorpalette) {
      try {
        const data = JSON.parse(
          atob(decodeURIComponent(this.$route.query.colorpalette))
        )
        this.updateThemeStyle(data)
      } catch (e) {}
    }
  },
  methods: {
    addStyleTag() {
      let head = document.head || document.getElementsByTagName('head')[0]
      const existingStyleElement = document.getElementById(
        'custom-theme-style-tag'
      )
      const styleElement =
        existingStyleElement || document.createElement('style')
      styleElement.type = 'text/css'
      styleElement.id = 'custom-theme-style-tag'
      this.__styleTag = styleElement
      head.appendChild(styleElement)
    },
    // for mac safari compact tab color
    updateMetaContent(color) {
      const existingMetaTag = document.querySelectorAll(
        'meta[name="theme-color"]'
      )
      if (existingMetaTag) {
        if (existingMetaTag.length) {
          existingMetaTag.forEach((node) => {
            node.content = color
          })
        }
      }
    },
    updateThemeStyle(data) {
      if (!data) {
        if (!this.__styleTag) {
          this.addStyleTag()
        }
        const styleElement = this.__styleTag
        styleElement.innerText = ''
        return
      }
      const styleContent = {}
      Object.keys(data).forEach((palette) => {
        Object.keys(data[palette]).forEach((theme) => {
          if (theme === 'light_theme') {
            const colorVariables = data[palette][theme]
            const primaryColor = colorVariables['--primary']
            const primaryAltColor = colorVariables['--primary-alt']
            colorVariables['--primary-button-hover-bg'] = getColorFadeValue(
              primaryColor,
              0.7
            )
            colorVariables['--outline-button-hover-bg'] = getColorFadeValue(
              primaryColor,
              0.2
            )

            styleContent[`:root`] = colorVariables
            this.updateMetaContent(primaryAltColor)
          } else if (theme === 'dark_theme') {
            const colorVariables = data[palette][theme]
            const primaryColor = colorVariables['--primary']
            const primaryAltColor = colorVariables['--primary-alt']
            colorVariables['--primary-button-hover-bg'] = getColorFadeValue(
              primaryColor,
              0.7
            )
            colorVariables['--default-tag-bg'] = getColorFadeValue(
              primaryColor,
              0.2
            )
            colorVariables['--outline-button-hover-bg'] = getColorFadeValue(
              primaryAltColor,
              0.2
            )

            styleContent[`[data-theme='dark-theme']`] = colorVariables
            this.updateMetaContent(primaryAltColor)
          }
        })
      })
      if (!this.__styleTag) {
        this.addStyleTag()
      }
      const styleElement = this.__styleTag
      if (styleElement) {
        const styleSheet = Object.keys(styleContent).reduce((str, key) => {
          str += `${key} {`
          str += Object.keys(styleContent[key]).reduce((st, variableName) => {
            st += `${variableName}: ${styleContent[key][variableName]};`

            return st
          }, ``)

          str += '}'

          return str
        }, '')
        styleElement.innerText = styleSheet
      }
    },
  },
  render() {
    return this.$scopedSlots.default()
  },
}
</script>
