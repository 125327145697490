<template>
  <div class="flex flex-col flex-wrap">
    <MUpload
      :key="renderCount"
      :headers="headers"
      :action="uploadUrl"
      :multiple="allowMultiFileSelect"
      name="file"
      :class="disabled && !preview ? 'fileListDisabled' : ''"
      :default-file-list="fileList"
      :show-upload-list="false"
      :open-file-dialog-on-click="!disabled"
      :style="disabled ? { opacity: 0.5 } : {}"
      v-bind="attrs"
      :before-upload="handleFileUpload"
      :disabled="disabled"
      @remove="handleFileRemove"
      @change="handleFileStatusChange"
      @preview="handlePreview"
      v-on="listeners"
    >
      <slot v-if="(!disabled && !onlyList) || preview">
        <a v-if="asLink" class="text-neutral" @click="handleButtonClick">
          <MIcon id="paperclip-icon" name="paperclip" />
          <span class="mx-2">{{ buttonText }}</span>
        </a>
        <MButton
          v-else
          id="attachment-btn"
          variant="neutral"
          :style="
            !ignorePreferenceValidation
              ? {
                  maxWidth: isHovering ? '300px' : '190px',
                  transitionProperty: 'max-width',
                  transitionDuration: '0.3s',
                  transitionTimingFunction: 'cubic-bezier(0.42, 0, 0.58, 1)',
                }
              : {}
          "
          :title="buttonText"
          class="flex flex-1 items-center text-ellipsis"
          @click="handleButtonClick"
          @mouseenter="isHovering = true"
          @mouseleave="isHovering = false"
        >
          <MIcon name="upload" />
          <div class="text-ellipsis box">
            <span class="mx-2">
              {{ buttonTextValue }}
            </span>
          </div>
        </MButton>
      </slot>
      <portal v-if="currentPreviewAttachment" to="image-preview">
        <div class="image-preview">
          <div class="actions text-right mb-2">
            <MButton
              shape="circle"
              :shadow="false"
              variant="transparent"
              class="mr-2"
              @click="downloadAttachment"
            >
              <MIcon name="download" size="lg" class="text-white" />
            </MButton>
            <MButton
              shape="circle"
              variant="transparent"
              :shadow="false"
              @click="currentPreviewAttachment = null"
            >
              <MIcon name="times" size="lg" class="text-white" />
            </MButton>
          </div>
          <div class="img" style="overflow: auto">
            <!-- <AttachmentViewer :attachment="currentPreviewAttachment" /> -->
            <img :src="currentPreviewAttachment.url" />
          </div>
        </div>
      </portal>
    </MUpload>
    <FileList
      v-if="showUploadList"
      :file-list="fileList"
      :disabled="disabled"
      :style="disabled ? { opacity: 0.5 } : {}"
      :uploading-file-list="uploadingFileList"
      @remove="handleFileRemove"
      @remove-uploading="handleRemoveUploading"
    />
    <FlotoConfirmModal
      v-if="deletingFile"
      :open="deletingFile"
      @hide="deletingFile = null"
      @confirm="handleRemoveFileConfirm(deletingFile)"
    >
      <template v-slot:icon>
        <i
          aria-label="icon: paper-clip"
          class="anticon anticon-paper-clip text-secondary-red"
        >
          <svg
            viewBox="64 64 896 896"
            data-icon="paper-clip"
            width="2em"
            height="2em"
            fill="currentColor"
            aria-hidden="true"
            focusable="false"
            class=""
          >
            <path
              d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 0 0 174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"
            ></path>
          </svg>
        </i>
      </template>
      <template v-slot:message>
        <div class="flex-wrap min-w-0 min-h-0">
          {{ $t('confirm_remove_item', { item: deletingFile.realName }) }}
        </div>
      </template>
    </FlotoConfirmModal>
  </div>
</template>

<script>
import Pick from 'lodash/pick'
import UniqBy from 'lodash/uniqBy'
import { transformAttachment } from '@data/attachment'
import { authComputed } from '@state/modules/auth'
import { PreferenceComputed } from '@state/modules/preference'
import { isLoggedIn } from '@utils/auth'
import FileList from './file-list'
// import AttachmentViewer from './attachment-viewer.vue'

export default {
  name: 'FlotoAttachment',
  components: { FileList },
  // components: { AttachmentViewer },
  model: {
    event: 'change',
  },
  props: {
    onlyList: { type: Boolean, default: false },
    usePublicApi: { type: Boolean, default: false },
    maxFiles: { type: Number, default: undefined },
    progressList: {
      type: Array,
      default() {
        return []
      },
    },
    allowedExtensions: {
      type: Array,
      default: undefined,
    },
    onlyFileWithoutExtension: { type: Boolean, default: false },
    asLink: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array],
      default: () => [],
    },
    multiple: {
      type: Boolean,
      // eslint-disable-next-line
      default: true,
    },
    showUploadList: {
      type: Boolean,
      // eslint-disable-next-line
      default: true,
    },
    buttonText: {
      type: String,
      required: false,
      default() {
        return `${this.$tc('attach_file', 2)}`
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    // maxAllowedFileSize: {
    //   type: Number,
    //   default: 5,
    // },
    uploadApi: {
      type: [String, Function],
      default: undefined,
    },
    ignorePreferenceValidation: {
      type: Boolean,
      default: false,
    },
    disableFileList: { type: Boolean, default: false },
  },
  data() {
    this.supportedExtensionToPreview = ['jpeg', 'jpg', 'png', 'gif']
    return {
      currentPreviewAttachment: null,
      renderCount: 1,
      alreadyNotifyError: false,
      isHovering: false,
      uploadingFileList: [],
      deletingFile: null,
    }
  },
  computed: {
    ...authComputed,
    ...PreferenceComputed,
    progressListComputed() {
      if (this.progressList.length) {
        return this.progressList
      }
      return this.fileList
    },
    allowMultiFileSelect() {
      return !this.ignorePreferenceValidation && this.allowedMaxFiles > 1
    },
    allowedMaxFiles() {
      if (this.ignorePreferenceValidation) {
        return this.maxFiles || 1
      }
      // by default set hardcoded 1000
      return 1000
    },
    fileList() {
      if ((this.value || []).length) {
        return this.value.map((a) => {
          const transformed = transformAttachment(
            a,
            Boolean(this.accessToken),
            this.isPortalLogin && !this.publicAccessToken
          )
          if (this.accessToken) {
            transformed.url += this.accessToken
            transformed.previewUrl += this.accessToken
          } else if (this.publicAccessToken) {
            transformed.url += this.publicAccessToken
            transformed.previewUrl += this.publicAccessToken
          }
          return transformed
        })
      }
      return []
    },
    headers() {
      if (!this.loggedIn && this.publicAccessToken) {
        return { Authorization: 'Bearer ' + this.publicAccessToken }
      }
      if (this.accessToken) {
        return { Authorization: 'Bearer ' + this.accessToken }
      }
      return {}
    },
    uploadUrl() {
      if (this.uploadApi) {
        return this.uploadApi
      }
      if (this.usePublicApi) {
        return '/api/public/upload/file'
      }
      if (this.accessToken) {
        return `/api/upload/file?validate=${!this.ignorePreferenceValidation}`
      }
      if (isLoggedIn()) {
        return '/api/upload/file'
      }
      return '/api/public/upload/file'
    },
    attrs() {
      const attrs = { ...this.$attrs }
      if (this.onlyList) {
        attrs.fileList = this.progressListComputed
      }
      return attrs
    },
    listeners() {
      const { change, preview, ...listeners } = this.$listeners
      return listeners
    },
    buttonTextValue() {
      if (this.isHovering && !this.ignorePreferenceValidation) {
        return `${this.$tc('max_file_size')} : ${this.maxAllowedFileSize}`
      }
      return this.buttonText
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue && this.onlyList) {
        this.renderCount = this.renderCount + 1
      }
    },
  },
  methods: {
    handleButtonClick() {
      this.alreadyNotifyError = false
    },
    downloadAttachment() {
      window.open(this.currentPreviewAttachment.url, '__blank')
    },
    handlePreview(file) {
      const ext = file.name.split('.').pop()
      if (this.disableFileList) {
        return
      }
      if (this.supportedExtensionToPreview.indexOf(ext) >= 0) {
        this.currentPreviewAttachment = file
      } else {
        window.open(file.previewUrl, '__blank')
      }
    },
    handleFileStatusChange({ file, fileList }) {
      this.$emit(
        'file-list-updated',
        fileList.filter((f) => f.status !== 'removed')
      )
      if (file.status === 'error') {
        const uIndex = this.uploadingFileList
          .map((i) => i.name)
          .indexOf((f) => f.name === file.name)
        this.uploadingFileList = UniqBy(
          [
            ...this.uploadingFileList.slice(0, uIndex),
            { name: file.name, error: true },
            ...this.uploadingFileList.slice(uIndex + 1),
          ],
          'name'
        )
        this.$emit('upload-done')
        this.$emit('upload-error', file.response.userMessage)
        return
      }
      if (file.response && file.status === 'done') {
        fileList.forEach((f) => {
          if (f.response) {
            if (this.accessToken) {
              f.url =
                transformAttachment(
                  f.response,
                  Boolean(this.accessToken),
                  this.isPortalLogin
                ).url + this.accessToken
              f.previewUrl =
                transformAttachment(
                  f.response,
                  Boolean(this.accessToken),
                  this.isPortalLogin
                ).previewUrl + this.accessToken
            } else {
              f.url = transformAttachment(
                f.response,
                Boolean(this.accessToken),
                this.isPortalLogin
              ).url
              f.previewUrl = transformAttachment(
                f.response,
                Boolean(this.accessToken),
                this.isPortalLogin
              ).previewUrl
            }
          }
        })
        setTimeout(() => {
          let validfileList = [
            ...(this.value || []),
            Pick(file.response, ['realName', 'refFileName', 'id']),
          ]
          let shouldRerender = false
          if (
            (!this.ignorePreferenceValidation || this.maxFiles) &&
            this.allowedMaxFiles &&
            fileList.length > this.allowedMaxFiles
          ) {
            validfileList = validfileList
              .reverse()
              .slice(0, this.allowedMaxFiles)
            // emiti file-list-updated with valid file list
            const fileLists = fileList.filter(
              (f) =>
                f.status !== 'removed' &&
                (validfileList || [])
                  .map((v) => v.refFileName)
                  .indexOf((f.response || {}).refFileName) >= 0
            )
            this.$emit('file-list-updated', fileLists)
            // max multi file upload validation
            if (!this.alreadyNotifyError && !this.maxFiles) {
              this.$errorNotification({
                duration: 5,
                message: this.$t('max_attachment_count_violation'),
                description: this.$t(
                  'max_attachment_count_violation_discription',
                  {
                    count: this.allowedMaxFiles,
                  }
                ),
              })
              this.alreadyNotifyError = true
            }
            shouldRerender = true
          }
          this.$emit('change', validfileList)
          this.$emit('upload-done')
          const uploadedFileNames = validfileList.map((i) => i.realName)
          this.uploadingFileList = this.uploadingFileList.filter(
            (f) => uploadedFileNames.indexOf(f.name) === -1
          )
          if (shouldRerender) {
            this.$nextTick(() => this.renderCount++)
          }
        }, 450)
      }
    },
    handleFileRemove(file) {
      this.deletingFile = file
    },
    handleRemoveFileConfirm(file) {
      let newList
      const refFileName = file.refFileName || (file.response || {}).refFileName
      if (!refFileName) {
        return
      }
      newList = (this.value || []).filter((f) => f.refFileName !== refFileName)
      this.$emit('change', newList)
      if (this.onlyList) {
        const updatedFileList = this.progressListComputed.filter((f) => {
          if (f.response && f.response.refFileName === refFileName) {
            return false
          }
          return true
        })
        this.$emit('file-list-updated', updatedFileList)
      }
      return true
    },
    handleFileUpload(file, fileList) {
      const maxFileSize = this.maxAllowedFileSize * 1024 * 1024
      let isValidFile = true
      let isValidFileType = true
      let ext = file.name.split('.').pop()
      ext = ext === file.name ? '' : ext
      if (!this.ignorePreferenceValidation && file.size > maxFileSize) {
        // max file size validation
        this.$errorNotification({
          duration: 5,
          message: this.$t('unsupported_file_size'),
          description: this.$t('only_supported_file_size', {
            fileSize: `${this.maxAllowedFileSize} MB`,
          }),
        })
        isValidFile = false
      }
      if (this.onlyFileWithoutExtension) {
        if (!ext) {
          isValidFileType = true
        } else if (ext) {
          isValidFileType = false
          this.$errorNotification({
            duration: 5,
            message: this.$t('unsupported_file_type'),
            description: this.$t('only_file_without_extension'),
          })
        }
      } else if (
        this.allowedExtensions &&
        this.allowedExtensions.indexOf(ext.toLowerCase()) === -1
      ) {
        this.$errorNotification({
          duration: 5,
          message: this.$t('unsupported_file_type'),
          ...(this.allowedExtensions.length === 1
            ? {
                description: this.$t('only_supported_file_type', {
                  fileTypes: this.allowedExtensions,
                }),
              }
            : {
                description: this.$t('only_supported_multiple_file_types', {
                  fileTypes: this.allowedExtensions.join(', '),
                }),
              }),
        })
        isValidFileType = false
      }

      const blockFileAttachmentExt = this.allowedFileExtensions
      if (
        !this.ignorePreferenceValidation &&
        blockFileAttachmentExt &&
        blockFileAttachmentExt.indexOf(ext.toLowerCase()) >= 0
      ) {
        this.$errorNotification({
          message: this.$t('unsupported_file_type'),
          description: this.$t('blocked_file_attachments', {
            blockedExtension: blockFileAttachmentExt.join(', '),
          }),
        })
        isValidFileType = false
      }
      if (!isValidFile) {
        return Promise.reject(new Error(this.$t('unsupported_file_size')))
      }
      if (!isValidFileType) {
        return Promise.reject(new Error(this.$t('unsupported_file_type')))
      }
      this.$emit('upload-start')
      this.uploadingFileList = UniqBy([
        ...this.uploadingFileList,
        { name: file.name, error: false },
      ])
      return true
    },
    handleRemoveUploading(file) {
      this.uploadingFileList = this.uploadingFileList.filter(
        (f) => f.name !== file.name
      )
    },
  },
}
</script>

<style lang="less">
.fileListDisabled {
  .@{ant-prefix}icon:not(:first-child) {
    display: none;
  }
}

.image-preview {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.85);

  .img {
    flex: 1;
    height: 100%;
    text-align: center;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
